import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { LinkedEnterpriseVerify } from '@pepper-ui/common';
import {
  setLinkedEnterpriseNumber,
  setCompanyData,
} from '../../features/registration/registrationJourneySlice';
import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../../features/app/appSlice';

import { getCompanyData } from '../../firebase';
import useTitle from '../../hooks/useTitle';

const LinkedEnterpriseNumber = () => {
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;
  const [notValidAPIError, setNotValidAPIError] = useState(false);
  const [alreadyExistsAPIError, setAlreadyExistsAPIError] = useState(false);
  const [linkedEnterpriseNumberLocal, setLinkedEnterpriseNumberLocal] =
    useState('');

  const dispatch = useDispatch();
  const history = useHistory();

  useTitle(
    'SMEReg.LinkedEnterpriseNumber.s12',
    'Enter linked enterprise registration number - OZEV Electric Vehicle Chargepoint Grant - GOV.UK'
  );

  const handleCompanyRegistration = (event) => {
    setLinkedEnterpriseNumberLocal(event.target.value);
  };

  const errorInvalidFormat = copyProvider.getCopy(
    'SMEReg.LinkedEnterpriseNumber.s1',
    'Enter a valid Company Registration number that consists of 8 characters and can contain letters and numbers'
  );

  const errorNotValidAPI = copyProvider.getCopy(
    'SMEReg.LinkedEnterpriseNumber.s2',
    'Entered Company Registration number not recognised'
  );

  const errorAlreadyExistsAPI = copyProvider.getCopy(
    'SMEReg.LinkedEnterpriseNumber.s9',
    'This number is already in use with another account'
  );

  const handleBtnClick = () => {
    dispatch(setLinkedEnterpriseNumber(linkedEnterpriseNumberLocal));
    getCompanyData({ companyRegNumber: linkedEnterpriseNumberLocal })
      .then((response) => {
        const { data } = response;
        dispatch(setCompanyData(data));
        if (
          data !== null &&
          data.companyRegNumber === linkedEnterpriseNumberLocal
        ) {
          history.push('/sme-reg/confirm-business-details');
        } else {
          setNotValidAPIError(true);
        }
      })
      .catch((error) => {
        if (error.code === 'functions/already-exists') {
          setAlreadyExistsAPIError(true);
        } else {
          setNotValidAPIError(true);
        }
      });
  };

  const onLinkClick = () => {
    history.push('/sme-reg/company-identification-vat');
  };
  const pageModel = (
    <LinkedEnterpriseVerify
      summary={{
        title: copyProvider.getCopy(
          'Common.error-summary',
          'There is a problem'
        ),
      }}
      heading={copyProvider.getCopy(
        'SMEReg.LinkedEnterpriseNumber.s3',
        'Enter the company registration number of the enterprise that owns or controls your company'
      )}
      body={{
        line1: copyProvider.getCopy(
          'SMEReg.LinkedEnterpriseNumber.s4',
          'We will use this to confirm the details of your linked enterprise company'
        ),
        line2: copyProvider.getCopy(
          'SMEReg.LinkedEnterpriseNumber.s10',
          'The company registration number is the best number to use for a fast registration.'
        ),
      }}
      companyHouseInput={{
        label: copyProvider.getCopy(
          'SMEReg.LinkedEnterpriseNumber.s6',
          'Company registration number'
        ),
        hint: copyProvider.getCopy(
          'SMEReg.LinkedEnterpriseNumber.s5',
          'Companies House provides this number when a company registers with them.'
        ),
        value: linkedEnterpriseNumberLocal,
        onChange: handleCompanyRegistration,
        errorMessage: errorInvalidFormat,
      }}
      link={{
        text: copyProvider.getCopy(
          'SMEReg.LinkedEnterpriseNumber.s7',
          "I don't have a company registration number"
        ),
        to: '/sme-reg/company-VAT-number',
        onClick: onLinkClick,
      }}
      vatHint={copyProvider.getCopy(
        'landlordReg.LinkedEnterpriseNumber.s11',
        'You can use the VAT registration number of your controlling enterprise. Ask them for this information.'
      )}
      button={{
        text: copyProvider.getCopy(
          'SMEReg.LinkedEnterpriseNumber.s8',
          'Continue'
        ),
        onClick: handleBtnClick,
      }}
      serviceErrors={{
        notValidAPI: {
          hasError: notValidAPIError,
          message: errorNotValidAPI,
        },
        alreadyExistsAPI: {
          hasError: alreadyExistsAPIError,
          message: errorAlreadyExistsAPI,
        },
      }}
    />
  );

  return pageModel;
};

export default LinkedEnterpriseNumber;
