import React from 'react';
import { Link } from 'react-router-dom';
import { getClaimsByInstallerId, getInstallerDetails } from '../../firebase';
import ClaimStatusClassName from '../../components/common/ClaimStatus';
import convertDateToDisplay from '../convertDateToDisplay';
// eslint-disable-next-line import/prefer-default-export

export const setToLink = (claimStatus, grantType) => {
  let linkDirectedPage = {};

  if (grantType === 'Renters and flat owners') {
    linkDirectedPage = {
      'READY TO CLAIM':
        '/evhs/installer/claim-grant/review-customer-application',
      'SUBMITTED FOR REVIEW':
        '/evhs/installer/claim-grant/view-submitted-claim',
      'WAITING DYNAMICS': '/evhs/installer/claim-grant/view-submitted-claim',
      'APPROVED FOR PAYMENT': '/evhs/installer/claim-grant/view-approved-claim',
      PAYMENT: '/evhs/installer/claim-grant/view-approved-claim',
      DECLINED: '/evhs/installer/claim-grant/view-declined-claim',
      REJECTED: '/resubmit-journey/resubmit-claim',
      'EXPIRING SOON': '/application-renewed',
    };
  } else if (
    ['Residential landlord', 'Commercial landlord'].includes(grantType)
  ) {
    linkDirectedPage = {
      'INSTALLER CLAIM INCOMPLETE': '/evcg/dashboardHub/view-grant-application',
      'READY FOR INSTALLER CLAIM': '/evcg/dashboardHub/view-grant-application',
      RESUBMITTED: '/evcg/dashboardHub/view-application-submitted-by-installer',
      'APPROVED FOR PAYMENT': '/evcg/dashboardHub/approved-for-payment',
      DECLINED: '/evcg/dashboardHub/declined',
      PAYMENT: '/evcg/dashboardHub/approved-for-payment',
      'SUBMITTED BY INSTALLER':
        '/evcg/dashboardHub/view-application-submitted-by-installer',
      'SEND TO INSTALLER':
        '/evcg/dashboardHub/view-application-sent-to-installer',
      'SUBMITTED FOR REVIEW': '/evcg/dashboardHub/submitted-for-review',
      'WAITING DYNAMICS': '/evcg/dashboardHub/submitted-for-review',
      REJECTED: '/resubmit-journey/resubmit-claim',
      'INSTALLER CLAIMED': '/evcg/dashboardHub/submitted-for-review',
      'EXPIRING SOON': '/application-renewed',
      EXPIRED: '/landlord-application/application-expired-residential',
    };
  } else if (grantType === 'Residential car park') {
    linkDirectedPage = {
      'READY TO CLAIM': '/car-parks/installer-2/check-customer-application',
      'INSTALLER CLAIM INCOMPLETE':
        '/car-parks/installer-2/check-customer-application',
      'SUBMITTED FOR REVIEW': '/car-parks/installer-2/submitted-for-review',
      'WAITING DYNAMICS': '/car-parks/installer-2/submitted-for-review',
      DECLINED: '/car-parks/installer-2/declined',
      'APPROVED FOR PAYMENT': '/car-parks/installer-2/approved-for-payment',
      PAYMENT: '/car-parks/installer-2/approved-for-payment',
      'EXPIRING SOON': '/application-renewed',
      EXPIRED: '/landlord-application/application-expired-residential',
      REJECTED: '/resubmit-journey/resubmit-claim',
    };
  } else if (grantType === 'Staff and fleet car park') {
    linkDirectedPage = {
      'READY TO CLAIM': '/car-parks/installer-2/sme/check-customer-application',
      'INSTALLER CLAIM INCOMPLETE':
        '/car-parks/installer-2/sme/check-customer-application',
      'SUBMITTED FOR REVIEW': '/car-parks/installer-2/sme/submitted-for-review',
      'WAITING DYNAMICS': '/car-parks/installer-2/sme/submitted-for-review',
      DECLINED: '/car-parks/installer-2/sme/declined',
      PAYMENT: '/car-parks/installer-2/sme/approved-for-payment',
      'APPROVED FOR PAYMENT': '/car-parks/installer-2/sme/approved-for-payment',
      'EXPIRING SOON': '/application-renewed',
      EXPIRED: '/landlord-application/application-expired-residential',
      REJECTED: '/resubmit-journey/resubmit-claim',
    };
  }

  return linkDirectedPage[claimStatus] || '';
};

export const fetchClaims = async ({
  currentPage,
  recordsPerPage,
  dispatch,
  setTotalRecords,
  history,
}) => {
  try {
    const response = await getClaimsByInstallerId({ searchTerm: '' });
    if (response?.data?.success) {
      setTotalRecords(response.data.claims.length);
      const startIndex = (currentPage - 1) * recordsPerPage;
      const claimsForCurrentPage = response.data.claims.slice(
        startIndex,
        startIndex + recordsPerPage
      );
      // eslint-disable-next-line no-undef
      dispatch(setClaimsData(claimsForCurrentPage));
    }
  } catch (err) {
    history.push('/installer/claim-grant/installer-dashboard-first-time');
  }
};

export const getInstaller = ({
  setIsResidential,
  dispatch,
  INSTALLER_ACCOUNT_TYPE,
}) => {
  getInstallerDetails()
    .then((response) => {
      if (response.data.success) {
        if (
          response.data.account_type.toLowerCase() === INSTALLER_ACCOUNT_TYPE
        ) {
          getClaimsByInstallerId({ searchTerm: '' })
            .then((res) => {
              if (res.data.success && res.data.totalClaims > 0) {
                dispatch(setIsResidential(response.data.isResidential));
              }
            })
            .catch(() => {
              // TODO: error handling later
            });
        }
      }
    })
    .catch(() => {
      // Todo later error handling
    });
};

export const setLinkText = (
  claimStatus,
  claimSubmissionDeadlineDate,
  copyProvider
) => {
  if (
    claimStatus === 'READY TO CLAIM' ||
    claimStatus === 'READY FOR INSTALLER CLAIM' ||
    claimStatus === 'INSTALLER CLAIM INCOMPLETE'
  ) {
    return (
      <span>
        {copyProvider.getCopy(
          'Installer.Dashboard.InstallerDashboard.s20',
          'Submit claim by '
        )}
        {claimSubmissionDeadlineDate}
      </span>
    );
  }

  if (
    [
      'SUBMITTED FOR REVIEW',
      'WAITING DYNAMICS',
      'APPROVED FOR PAYMENT',
      'EXPIRED',
      'CANCELLED',
      'PAYMENT',
      'SEND TO INSTALLER',
      'INSTALLER CLAIMED',
      'RESUBMITTED',
      'SEND TO DYNAMICS',
    ].includes(claimStatus)
  ) {
    return copyProvider.getCopy(
      'Installer.Dashboard.InstallerDashboard.s21',
      'View claim '
    );
  }

  if (claimStatus === 'DECLINED') {
    return copyProvider.getCopy(
      'Installer.Dashboard.InstallerDashboard.s22',
      'View declined claim'
    );
  }

  if (claimStatus === 'REJECTED') {
    return (
      <span>
        {copyProvider.getCopy(
          'Installer.Dashboard.InstallerDashboard.s23',
          'Resubmit claim by '
        )}
        {claimSubmissionDeadlineDate}
      </span>
    );
  }

  if (claimStatus === 'EXPIRING SOON') {
    return (
      <span>
        {copyProvider.getCopy(
          'Installer.Dashboard.InstallerDashboard.s30',
          'Renew by '
        )}
        {claimSubmissionDeadlineDate}
      </span>
    );
  }

  return '';
};

export const populateTable = (
  claimsDataValue,
  mapDashboardStatuses,
  handleLinkClick,
  copyProvider
) => {
  return claimsDataValue?.length
    ? claimsDataValue.map((customer) => ({
        cells: [
          { children: customer.customerReferenceNumber },
          { children: customer.grant_type },
          { children: `${customer.firstName} ${customer.lastName}` },
          { children: customer.postCode },
          {
            children: ClaimStatusClassName(
              mapDashboardStatuses[customer.claimStatus.toUpperCase()]
            ),
          },
          {
            children: (
              <Link to={{}} onClick={() => handleLinkClick(customer)}>
                {setLinkText(
                  customer.claimStatus.toUpperCase(),
                  convertDateToDisplay(customer.claim_submission_deadline_date),
                  copyProvider
                )}
              </Link>
            ),
          },
        ],
      }))
    : [];
};

// eslint-disable-next-line no-shadow
export const handleLinkClick = (customer, setToLink) => {
  return customer.claimStatus.toUpperCase() === 'EXPIRING SOON'
    ? window.location.assign(
        `${setToLink(
          customer.claimStatus.toUpperCase(),
          customer.grant_type
        )}/${customer.grant_type}/${customer.document_id}`
      )
    : window.location.assign(
        `${setToLink(
          customer.claimStatus.toUpperCase(),
          customer.grant_type
        )}/${customer.document_id}`
      );
};
export const getInstallerDashboard = (
  // eslint-disable-next-line no-shadow
  getInstallerDetails,
  // eslint-disable-next-line no-shadow
  getClaimsByInstallerId,
  dispatch,
  setIsResidential,
  INSTALLER_ACCOUNT_TYPE
) => {
  getInstallerDetails()
    .then((response) => {
      if (response.data.success) {
        if (
          response.data.account_type.toLowerCase() === INSTALLER_ACCOUNT_TYPE
        ) {
          getClaimsByInstallerId({ searchTerm: '' })
            .then((res) => {
              if (res.data.success && res.data.totalClaims > 0) {
                dispatch(setIsResidential(response.data.isResidential));
              }
            })
            .catch(() => {
              // TODO: error handling later
            });
        }
      }
    })
    .catch(() => {
      // TODO: error handling later
    });
};
export const handleAddCustomerDetailsSubmit = () => {
  window.location.assign('/evhs/installer/application-setup/customer-details');
};
