// eslint-disable-next-line import/prefer-default-export
export const getInstallerDashboard = (
  getInstallerDetails,
  getClaimsByInstallerId,
  dispatch,
  setIsResidential,
  INSTALLER_ACCOUNT_TYPE
) => {
  getInstallerDetails()
    .then((response) => {
      if (response.data.success) {
        if (
          response.data.account_type.toLowerCase() === INSTALLER_ACCOUNT_TYPE
        ) {
          getClaimsByInstallerId({ searchTerm: '' })
            .then((res) => {
              if (res.data.success && res.data.totalClaims > 0) {
                dispatch(setIsResidential(response.data.isResidential));
              }
            })
            .catch(() => {
              // TODO: error handling later
            });
        }
      }
    })
    .catch(() => {
      // TODO: error handling later
    });
};
