import {
  setDocumentId,
  setExpiredClaim,
} from '../../features/dashboard/dashboardSlice';

// eslint-disable-next-line import/prefer-default-export
export const handleClick = (e, dispatch) => {
  if (e.claim_status === 'Expired') {
    dispatch(setExpiredClaim(e));
    window.location.assign(`${e.link}/${e.document_id}`);
    return;
  }
  if (e.isCarParkClaim) {
    dispatch(setDocumentId(e.document_id));
    window.location.assign(`/car-parks${e.link}/${e.document_id}`);
    return;
  }
  dispatch(setDocumentId(e.document_id));
  window.location.assign(`/evcg${e.link}`);
};
export const handleClickPmc = (e, dispatch) => {
  dispatch(setDocumentId(e.document_id));
  if (e.grant_type === 'CAR_PARK_RESIDENTIAL')
    window.location.assign(`/car-parks${e.link}/${e.document_id}`);
  else window.location.assign(`/evcg${e.link}`);
};
export const handleClickSme = (e, dispatch) => {
  if (e.claim_status === 'Expired') {
    dispatch(setExpiredClaim(e));
    window.location.assign(`${e.link}/${e.document_id}`);
    return;
  }
  if (e.isCarParkClaim) {
    dispatch(setDocumentId(e.document_id));
    window.location.assign(`/car-parks${e.link}/${e.document_id}`);
  }
};
