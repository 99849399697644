// eslint-disable-next-line import/prefer-default-export
export const fetchClaims = async (
  getClaimsByInstallerId,
  setTotalRecords,
  currentPage,
  recordsPerPage,
  dispatch,
  setClaimsData,
  history
) => {
  try {
    const response = await getClaimsByInstallerId({ searchTerm: '' });
    if (response?.data?.success) {
      setTotalRecords(response.data.claims.length);
      const startIndex = (currentPage - 1) * recordsPerPage;
      const claimsForCurrentPage = response.data.claims.slice(
        startIndex,
        startIndex + recordsPerPage
      );
      dispatch(setClaimsData(claimsForCurrentPage));
    }
  } catch (err) {
    history.push('/installer/claim-grant/installer-dashboard-first-time');
  }
};
