import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FileUpload, Table } from 'govuk-react-jsx';
import readFileAsDataUrl from '../../utils/readFileAsDataUrl';

const FileManager = ({
  name,
  hint,
  validFileTypes,
  multiple,
  errorMessage,
  fileSize,
  accept,
  hasMoreFilesUploadError,
  handleUpload,
  evidence,
  handleDelete,
  isInvoice,
  setUploadErrorParent,
}) => {
  const [errorUpload, setUploadError] = useState(null);

  useEffect(() => {
    if (hasMoreFilesUploadError) {
      setUploadError(errorMessage.invalidMoreFilesError);
      setUploadErrorParent(errorMessage.invalidMoreFilesError);
    } else {
      setUploadError(null);
    }
  }, [hasMoreFilesUploadError]);

  const handleUploadedFile = async (e) => {
    const uploadFiles = e.target.files;
    let hasError = false;
    const promises = [];

    for (let i = 0; i < uploadFiles.length; i += 1) {
      if (
        !validFileTypes.includes(uploadFiles[i].type) &&
        uploadFiles[i].size > fileSize
      ) {
        setUploadError(errorMessage.invalidSizeAndFormat);
        setUploadErrorParent(errorMessage.invalidSizeAndFormat);
        hasError = true;
      } else if (!validFileTypes.includes(uploadFiles[i].type)) {
        setUploadError(errorMessage.invalidFileFormatError);
        setUploadErrorParent(errorMessage.invalidFileFormatError);
        hasError = true;
        break;
      } else if (uploadFiles[i].size > fileSize) {
        setUploadError(errorMessage.invalidSizeError);
        setUploadErrorParent(errorMessage.invalidSizeError);
        hasError = true;
        break;
      } else {
        promises.push(readFileAsDataUrl(uploadFiles[i]));
        setUploadError('');
        setUploadErrorParent('');
      }
    }

    if (!hasError) {
      const results = await Promise.all(promises);
      handleUpload(results);
    }
  };

  const generateRowsForDeleteTable = (doc) => {
    const rows = doc.map((file, index) => {
      return {
        cells: [
          {
            children: file.fileName,
            className: 'app-table-cell-one-additional',
          },
          {
            children: (
              <Link
                to="/"
                onClick={(event) => handleDelete(event, index, file.filePath)}
              >
                Delete
              </Link>
            ),
            className: 'app-table-cell-two-additional',
          },
        ],
      };
    });
    return rows;
  };

  return (
    <>
      <FileUpload
        id={name}
        errorMessage={errorUpload && errorUpload}
        name={name}
        onChange={handleUploadedFile}
        multiple={multiple}
        accept={accept}
        hint={{ children: hint }}
        data-testid="file-upload"
      />
      {evidence && evidence.length > 0 && !isInvoice && (
        <>
          <h3 className="govuk-heading-s govuk-!-margin-top-6">
            Files uploaded
          </h3>
          <div className="app-table-container">
            <Table
              className="app-container-flex"
              rows={generateRowsForDeleteTable(evidence)}
            />
          </div>
        </>
      )}
    </>
  );
};

FileManager.propTypes = {
  name: PropTypes.string.isRequired,
  hint: PropTypes.array,
  validFileTypes: PropTypes.array.isRequired,
  errorMessage: PropTypes.shape({
    invalidFileFormatError: PropTypes.object,
    invalidSizeError: PropTypes.object,
    invalidMoreFilesError: PropTypes.object,
    invalidSizeAndFormat: PropTypes.object,
  }).isRequired,
  multiple: PropTypes.bool,
  fileSize: PropTypes.number.isRequired,
  accept: PropTypes.string.isRequired,
  hasMoreFilesUploadError: PropTypes.bool,
  handleUpload: PropTypes.func.isRequired,
  evidence: PropTypes.array.isRequired,
  handleDelete: PropTypes.func.isRequired,
  isInvoice: PropTypes.bool,
  setUploadErrorParent: PropTypes.any,
};

FileManager.defaultProps = {
  hint: [],
  isInvoice: false,
  multiple: false,
  hasMoreFilesUploadError: false,
  setUploadErrorParent: [],
};

export default FileManager;
