/**
 * Formats a date, returns a string with full month name (January, February, etc) followed by the year.
 * The year should be 4 numeric characters (2022. 2023 etc)
 * @param {String} date
 * @returns {String}
 */

export const formatDate = (date) => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const d = new Date(date);
  return `${monthNames[d.getMonth()]} ${d.getFullYear()}`;
};

export const saveAnalyticsCookie = (userSelection) => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  // expire after 1 years
  date.setTime(new Date(year + 1, month, day));
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `evcgAnalytics=${userSelection};${expires};path=/`;
};

export const saveLanguageCookie = (lang) => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  // expire after 1 years
  date.setTime(new Date(year + 1, month, day));
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `lang=${lang};${expires};path=/`;
};
export const handleLandlordSearch = ({
  searchStatus,
  setSearchStatus,
  setErrorList,
  setTableLandlordRows,
  copyProvider,
  tableLandlordRows,
}) => {
  const { searchTerm, searched, allData } = searchStatus;

  // Case 1: Search term is less than 3 characters
  if (searchTerm.length < 3) {
    setSearchStatus({
      ...searchStatus,
      errorMessage: copyProvider.getCopy(
        'dashboardHub.Dashboard.s27',
        'Enter 3 or more characters'
      ),
    });
    setErrorList([
      {
        children: copyProvider.getCopy(
          'dashboardHub.Dashboard.s27',
          'Enter 3 or more characters'
        ),
        href: '#input-with-hint-text',
      },
    ]);
    return;
  }

  // Case 2: Search term contains invalid characters
  if (!/^[a-z0-9 -]*$/i.test(searchTerm)) {
    setSearchStatus({
      ...searchStatus,
      errorMessage: copyProvider.getCopy(
        'dashboardHub.Dashboard.s28',
        'Enter a valid character'
      ),
    });
    setErrorList([
      {
        children: copyProvider.getCopy(
          'dashboardHub.Dashboard.s27',
          'Enter 3 or more characters'
        ),
        href: '#input-with-hint-text',
      },
    ]);
    return;
  }

  // Case 3: Valid search term - perform filtering
  const all = searched ? allData : tableLandlordRows;
  const searchRows = all.filter((entry) => {
    const { cells } = entry;
    return (
      cells[0].children
        .toLowerCase()
        .startsWith(searchTerm.toLocaleLowerCase()) ||
      cells[2].children.toLowerCase().startsWith(searchTerm.toLocaleLowerCase())
    );
  });

  // Update the search status and error list
  setSearchStatus({
    ...searchStatus,
    searched: true,
    allData: all,
    resultCount: searchRows.length,
    errorMessage: '',
  });
  setErrorList([]);

  // Update the table rows if search results are found
  if (searchRows.length > 0) {
    setTableLandlordRows(searchRows);
  }
};
export const handleLandlordSearchPmc = ({
  searchStatus,
  setSearchStatus,
  setErrorList,
  setTablePmcRows,
  copyProvider,
  tablePmcRows,
}) => {
  const { searchTerm, searched, allData } = searchStatus;
  if (searchTerm.length < 3) {
    setSearchStatus({
      ...searchStatus,
      errorMessage: copyProvider.getCopy(
        'dashboardHub.DashboardPMC.s27',
        'Enter 3 or more characters'
      ),
    });
    setErrorList([
      {
        children: copyProvider.getCopy(
          'dashboardHub.DashboardPMC.s27',
          'Enter 3 or more characters'
        ),
        href: '#input-with-hint-text',
      },
    ]);
    return;
  }

  if (!/^[a-z0-9 -]*$/i.test(searchTerm)) {
    setSearchStatus({
      ...searchStatus,
      errorMessage: copyProvider.getCopy(
        'dashboardHub.DashboardPMC.s28',
        'Enter a valid character'
      ),
    });
    setErrorList([
      {
        children: copyProvider.getCopy(
          'dashboardHub.DashboardPMC.s28',
          'Enter a valid character'
        ),
        href: '#input-with-hint-text',
      },
    ]);

    return;
  }

  const all = searched ? allData : tablePmcRows;
  const searchRows = all.filter((entry) => {
    const { cells } = entry;
    // cell 0 is reference, cell 2 is company and cell 3 is postcode
    return (
      cells[0]?.children
        ?.toLowerCase()
        .startsWith(searchTerm.toLocaleLowerCase()) ||
      cells[2]?.children
        ?.toLowerCase()
        .startsWith(searchTerm.toLocaleLowerCase()) ||
      cells[3]?.children
        ?.toLowerCase()
        .startsWith(searchTerm.toLocaleLowerCase())
    );
  });
  setSearchStatus({
    ...searchStatus,
    searched: true,
    allData: all,
    resultCount: searchRows.length,
    errorMessage: '',
  });

  setErrorList([]);

  if (searchRows.length > 0) {
    setTablePmcRows(searchRows);
  }
};
export const handleLandlordSearchSme = ({
  searchStatus,
  setSearchStatus,
  setErrorList,
  tableSMEdRows,
  copyProvider,
  setTableSMERows,
}) => {
  const { searchTerm, searched, allData } = searchStatus;
  if (searchTerm.length < 3) {
    setSearchStatus({
      ...searchStatus,
      errorMessage: copyProvider.getCopy(
        'dashboardHub.DashboardSME.s27',
        'Enter 3 or more characters'
      ),
    });
    setErrorList([
      {
        children: copyProvider.getCopy(
          'dashboardHub.DashboardSME.s27',
          'Enter 3 or more characters'
        ),
        href: '#input-with-hint-text',
      },
    ]);

    return;
  }

  if (!/^[a-z0-9 -]*$/i.test(searchTerm)) {
    setSearchStatus({
      ...searchStatus,
      errorMessage: copyProvider.getCopy(
        'dashboardHub.DashboardSME.s28',
        'Enter a valid character'
      ),
    });
    setErrorList([
      {
        children: copyProvider.getCopy(
          'dashboardHub.DashboardSME.s27',
          'Enter 3 or more characters'
        ),
        href: '#input-with-hint-text',
      },
    ]);

    return;
  }

  const all = searched ? allData : tableSMEdRows;
  const searchRows = all.filter((entry) => {
    const { cells } = entry;
    // cell 0 is reference and cell 2 is postcode
    return (
      cells[0].children
        .toLowerCase()
        .startsWith(searchTerm.toLocaleLowerCase()) ||
      cells[2].children.toLowerCase().startsWith(searchTerm.toLocaleLowerCase())
    );
  });
  setSearchStatus({
    ...searchStatus,
    searched: true,
    allData: all,
    resultCount: searchRows.length,
    errorMessage: '',
  });
  setErrorList([]);

  if (searchRows.length > 0) {
    setTableSMERows(searchRows);
  }
};
export const handleSearch = ({
  event,
  searchTerm,
  checkSearchTerm,
  searchTermErrorMsg,
  setErrorMsg,
  setErrorList,
  getClaimsByInstallerId,
  dispatch,
  setClaimsData,
  setSearchPerformed,
  setSearchHasNoResult,
}) => {
  event.preventDefault();
  let hasError = false;
  if (!checkSearchTerm.test(searchTerm)) {
    const searchTermError = {
      children: searchTermErrorMsg,
    };
    setErrorMsg(searchTermError);
    setErrorList([{ ...searchTermError, href: '#search' }]);
    hasError = true;
    return;
  }
  setErrorList([]);
  setErrorMsg(null);
  if (!hasError) {
    getClaimsByInstallerId({
      searchTerm: searchTerm.toLowerCase(),
    })
      .then(({ data: { claims, success } }) => {
        if (success) {
          dispatch(setClaimsData(claims));
          if (!claims.length) {
            setSearchHasNoResult(true);
          } else {
            setSearchPerformed(true);
            setSearchHasNoResult(false);
          }
        }
      })
      .catch(() => {
        setSearchHasNoResult(true);
      });
  }
};
