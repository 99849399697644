import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { SummaryList, Button } from 'govuk-react-jsx';
import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../../../features/app/appSlice';

import { getResubmitClaims } from '../../../features/resubmit/resubmitJourneySlice';
import { submitResubmitClaim } from '../../../firebase';
import { getClaimsData } from '../../../features/customer/customerJourneySlice';
import useTitle from '../../../hooks/useTitle';

const CheckAnswersResubmit = () => {
  const history = useHistory();
  const { id } = useParams();

  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const resubmitClaimsData = useSelector(getResubmitClaims);
  const claimsValue = useSelector(getClaimsData);

  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;

  useTitle(
    'Installer.Resubmit.CheckAnswersResubmit.s7',
    'Check your answers - OZEV Electric Vehicle Chargepoint Grant - GOV.UK'
  );

  const claimData = claimsValue.filter((c) => c.document_id === id)[0];
  const resubmitClaim = resubmitClaimsData.filter(
    (claim) => claim.documentId === id
  )[0];

  const headingTxt = copyProvider.getCopy(
    'Installer.Resubmit.CheckAnswersResubmit.s1',
    'Check before you submit'
  );
  const subheadintTxt = copyProvider.getCopy(
    'Installer.Resubmit.CheckAnswersResubmit.s2',
    'Your new evidence'
  );
  const linkTxt = copyProvider.getCopy(
    'Installer.Resubmit.CheckAnswersResubmit.s4',
    'Change'
  );
  const btnTxt = copyProvider.getCopy(
    'Installer.Resubmit.CheckAnswersResubmit.s6',
    'Confirm and resubmit claim'
  );

  const rowBody = (evidence, index) => {
    return (
      <li
        style={{ listStyle: 'none' }}
        key={
          evidence.document_id
            ? `${evidence.document_id}-${index}`
            : `key-${index}`
        }
      >
        {evidence.fileName || evidence.document_id || 'Unnamed File'}
        <br />
      </li>
    );
  };

  const imageCpNames = resubmitClaim?.chargepointEvidence?.map(
    (evidence, index) => rowBody(evidence, index)
  );

  const cpAndPropertyName = resubmitClaim?.carparkEvidence?.map(
    (evidence, index) => rowBody(evidence, index)
  );

  const diagramName = resubmitClaim?.diagramEvidence?.map((evidence, index) =>
    rowBody(evidence, index)
  );

  const invoiceName = resubmitClaim?.invoiceEvidence?.map((evidence, index) =>
    rowBody(evidence, index)
  );

  const cpPhotoRow = {
    actions: {
      items: [
        {
          children: linkTxt,
          href: `/resubmit-journey/resubmit-claim/${id}`,
        },
      ],
    },
    key: {
      children: copyProvider.getCopy(
        'Installer.Resubmit.CheckAnswersResubmit.s3',
        'Chargepoint photos'
      ),
    },
    value: {
      children: <ul>{imageCpNames}</ul>,
    },
  };

  const invoiceRow = {
    actions: {
      items: [
        {
          children: linkTxt,
          href: `/resubmit-journey/resubmit-claim/${id}`,
        },
      ],
    },
    key: {
      children: copyProvider.getCopy(
        'Installer.Resubmit.CheckAnswersResubmit.s5',
        'Invoice'
      ),
    },
    value: {
      children: <ul>{invoiceName}</ul>,
    },
  };

  const carparksAndPropertyPhotosRow = {
    actions: {
      items: [
        {
          children: linkTxt,
          href: `/resubmit-journey/resubmit-claim/${id}`,
        },
      ],
    },
    key: {
      children: copyProvider.getCopy(
        'Installer.Resubmit.CheckAnswersResubmit.s0',
        'Car park and property photos'
      ),
    },
    value: {
      children: <ul>{cpAndPropertyName}</ul>,
    },
  };

  const diagramsRow = {
    actions: {
      items: [
        {
          children: linkTxt,
          href: `/resubmit-journey/resubmit-claim/${id}`,
        },
      ],
    },
    key: {
      children: copyProvider.getCopy(
        'Installer.Resubmit.CheckAnswersResubmit.s0',
        'Diagrams'
      ),
    },
    value: {
      children: <ul>{diagramName}</ul>,
    },
  };

  const onResubmitClick = () => {
    submitResubmitClaim({
      id: claimData.document_id,
      claim_id: claimData.customerReferenceNumber,
      grant_type: claimData.grant_type,
    })
      .then((response) => {
        if (response.data.success) {
          history.push(
            `/resubmit-journey/confirmation-resubmitted/${claimData.document_id}`
          );
        }
      })
      .catch(() => {
        // TODO
      });
  };

  const generateRowsToDisplay = () => {
    const rows = [];
    const {
      chargepointEvidence,
      carparkEvidence,
      diagramEvidence,
      invoiceEvidence,
    } = resubmitClaim;

    if (chargepointEvidence && chargepointEvidence.length > 0) {
      rows.push(cpPhotoRow);
    }

    if (carparkEvidence && carparkEvidence.length > 0) {
      rows.push(carparksAndPropertyPhotosRow);
    }

    if (diagramEvidence && diagramEvidence.length > 0) {
      rows.push(diagramsRow);
    }

    if (invoiceEvidence && invoiceEvidence.length > 0) {
      rows.push(invoiceRow);
    }

    return rows;
  };

  return (
    <>
      <h1 className="govuk-heading-xl">{headingTxt}</h1>
      <h2 className="govuk-heading-m govuk-!-margin-top-9">{subheadintTxt}</h2>
      <SummaryList rows={generateRowsToDisplay()} />
      <Button className="govuk-!-margin-top-7" onClick={onResubmitClick}>
        {btnTxt}
      </Button>
    </>
  );
};

export default CheckAnswersResubmit;
