import React from 'react';
import PropTypes from 'prop-types';

const Pagination = ({
  totalRecords,
  recordsPerPage,
  currentPage,
  setCurrentPage,
}) => {
  const totalPages = Math.ceil(totalRecords / recordsPerPage);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const getVisiblePageNumbers = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i += 1) {
      if (
        i === 1 ||
        i === totalPages ||
        i === currentPage ||
        i === currentPage - 1 ||
        i === currentPage + 1
      ) {
        pages.push(i);
      } else if (pages[pages.length - 1] !== '...') {
        pages.push('...');
      }
    }
    return pages;
  };

  return (
    <nav
      className="govuk-pagination"
      aria-label="Pagination"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '20px',
        fontWeight: 'bold',
        fontSize: '20px',
      }}
    >
      {currentPage > 1 && (
        <div className="govuk-pagination__prev">
          <a
            className="govuk-link govuk-pagination__link"
            href="/installer/claim-grant/installer-dashboard"
            rel="prev"
            onClick={(event) => {
              event.preventDefault();
              handlePageChange(currentPage - 1);
            }}
          >
            <svg
              className="govuk-pagination__icon govuk-pagination__icon--prev"
              xmlns="http://www.w3.org/2000/svg"
              height="13"
              width="15"
              aria-hidden="true"
              focusable="false"
              viewBox="0 0 15 13"
            >
              <path d="m6.5938-0.0078125-6.7266 6.7266 6.7441 6.4062 1.377-1.449-4.1856-3.9768h12.896v-2h-12.984l4.2931-4.293-1.414-1.414z" />
            </svg>
            <span className="govuk-pagination__link-title">
              Previous<span className="govuk-visually-hidden"> page</span>
            </span>
          </a>
        </div>
      )}
      <ul
        style={{
          display: 'flex',
          listStyleType: 'none',
          padding: 0,
          margin: 0,
          gap: '10px',
        }}
      >
        {getVisiblePageNumbers().map((page) => {
          const isEllipsis = page === '...';
          return (
            <li
              key={page}
              className={
                isEllipsis
                  ? 'govuk-pagination__item govuk-pagination__item--ellipses'
                  : 'govuk-pagination__item'
              }
            >
              <a
                className="govuk-link govuk-pagination__link"
                href="/installer/claim-grant/installer-dashboard"
                aria-label={`Page ${page}`}
                data-testid="page"
                aria-current={page === currentPage ? 'page' : undefined}
                onClick={(e) => {
                  e.preventDefault();
                  if (!isEllipsis) {
                    handlePageChange(page);
                  }
                }}
                style={{
                  padding: '5px 10px',
                  textDecoration: isEllipsis ? 'none' : 'underline',
                  backgroundColor:
                    currentPage === page ? '#1d8feb' : 'transparent',
                  color: currentPage === page ? 'white' : '#4c2c92',
                  cursor: isEllipsis ? 'default' : 'pointer',
                  fontWeight: isEllipsis ? 'bold' : 'normal',
                }}
              >
                {page}
              </a>
            </li>
          );
        })}
      </ul>
      {currentPage < totalPages && (
        <div className="govuk-pagination__next">
          <a
            className="govuk-link govuk-pagination__link"
            href="/installer/claim-grant/installer-dashboard"
            rel="next"
            onClick={(event) => {
              event.preventDefault();
              handlePageChange(currentPage + 1);
            }}
          >
            <span className="govuk-pagination__link-title">
              Next
              <span className="govuk-visually-hidden"> page</span>
            </span>
            <svg
              className="govuk-pagination__icon govuk-pagination__icon--next"
              xmlns="http://www.w3.org/2000/svg"
              height="13"
              width="15"
              aria-hidden="true"
              focusable="false"
              viewBox="0 0 15 13"
            >
              <path d="m8.107-0.0078125-1.4136 1.414 4.2926 4.293h-12.986v2h12.896l-4.1855 3.9766 1.377 1.4492 6.7441-6.4062-6.7246-6.7266z" />
            </svg>
          </a>
        </div>
      )}
    </nav>
  );
};
// PropTypes for the component
Pagination.propTypes = {
  totalRecords: PropTypes.number.isRequired,
  recordsPerPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
};

export default Pagination;
