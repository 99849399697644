import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Label, Input, Button, ErrorSummary } from 'govuk-react-jsx';
import {
  setSecurityCode,
  getSecurityCode,
  // getAccountEmail,
} from '../../features/registration/registrationJourneySlice';
import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
  setDisplayGoBackLink,
  setUserLockedStatus,
} from '../../features/app/appSlice';
import { getUserInfo } from '../../features/dashboard/dashboardSlice';
import { checkValidEmailCode } from '../../regex/regex';
import useTitle from '../../hooks/useTitle';

const AccessCode = () => {
  const [errMessage, setErrMessage] = useState(null);
  const [errorList, setErrorList] = useState([]);
  const [securityCodeLocal, setSecurityCodeLocal] = useState('');
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const securityCodevalue = useSelector(getSecurityCode);
  const emailAccount = useSelector(getUserInfo);
  // const emailAddress = 'example@test.com';
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation;
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;
  useTitle(
    'Installer.AccessCode.s9',
    'Enter the code to confirm your email address - OZEV Electric Vehicle Chargepoint Grant - GOV.UK'
  );
  const invalidErrorMsg = {
    children: copyProvider.getCopy(
      'Installer.AccessCode.s12',
      'Enter a security code'
    ),
  };
  const wrongCodeErrorMsg = {
    children: copyProvider.getCopy(
      'Installer.AccessCode.s7',
      'Enter a valid security code'
    ),
  };
  const onChangeHandler = (event) => {
    setSecurityCodeLocal(event.target.value);
  };
  const btnClickHandler = (e) => {
    e.preventDefault();
    let hasError = false;
    const errorArray = [];
    setErrorList(errorArray);
    if (securityCodevalue === '') {
      hasError = true;
      setErrMessage(invalidErrorMsg);
      errorArray.push({ ...invalidErrorMsg, href: '#opt-number' });
      setSecurityCodeLocal('');
    } else if (checkValidEmailCode.test(securityCodevalue)) {
      hasError = true;
      setErrMessage(wrongCodeErrorMsg);
      errorArray.push({ ...wrongCodeErrorMsg, href: '#opt-number' });
      setSecurityCodeLocal('');
    } else {
      setErrMessage(null);
      setErrorList([]);
    }
    if (!hasError) {
      dispatch(setSecurityCode(securityCodeLocal));
      if (location.pathname === '/installer/access-code') {
        history.push('/installer/claim-grant/installer-dashboard');
      } else if (location.pathname === '/access-code') {
        history.push('/dashboardHub/dashboard');
      } else {
        setErrorList(errorArray);
      }
    } else {
      setErrorList(errorArray);
    }
  };

  const title = copyProvider.getCopy('Installer.AccessCode.s1', 'Translation');

  useEffect(() => {
    dispatch(setUserLockedStatus(true));
    dispatch(setDisplayGoBackLink(false));
    return () => {
      dispatch(setUserLockedStatus(true));
      dispatch(setDisplayGoBackLink(true));
    };
  }, []);

  const pageModel = (
    <>
      {errorList?.length > 0 && (
        <ErrorSummary
          errorList={errorList}
          titleChildren={copyProvider.getCopy(
            'Installer.AccessCode.s13',
            'There is a problem'
          )}
        />
      )}
      <h1 className="govuk-heading-xl">{title}</h1>
      <p className="govuk-body">
        {copyProvider.getCopy(
          'Installer.AccessCode.s2',
          'We have sent an email to: '
        )}
        {emailAccount.account_email ? (
          <b>{`${emailAccount.account_email} `}</b>
        ) : (
          '"X" '
        )}
        {copyProvider.getCopy(
          'Installer.AccessCode.s3',
          'with a security code. We have done this to help keep your account safe.'
        )}
      </p>
      <p className="govuk-body">
        {copyProvider.getCopy(
          'Installer.AccessCode.s4',
          'It may take a few minutes to arrive. Please check your junk or spam folder.'
        )}
      </p>
      <div className="govuk-form-group">
        <Label htmlFor="opt-number">
          {copyProvider.getCopy('Installer.AccessCode.s5', 'Security code')}
        </Label>
        <Input
          errorMessage={errMessage}
          className="govuk-input govuk-input--width-20"
          id="opt-number"
          name="opt-number"
          type="text"
          value={securityCodeLocal}
          onChange={(event) => onChangeHandler(event)}
        />
      </div>
      <p>
        <Link className="govuk-body" to="/access-code-resent">
          {copyProvider.getCopy('Installer.AccessCode.s10', 'Resend code')}
        </Link>
      </p>
      <br />
      <Button className="govuk-button" onClick={btnClickHandler}>
        {copyProvider.getCopy('Installer.AccessCode.s11', 'Continue')}
      </Button>
    </>
  );
  return pageModel;
};

export default AccessCode;
