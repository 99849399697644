import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Button, Input, ErrorSummary } from 'govuk-react-jsx';
import { useHistory, Link } from 'react-router-dom';
import Pagination from '../Pagination';
import { getClaimsByInstallerId, getInstallerDetails } from '../../../firebase';
import { handleSearch } from '../../../utils/helpers';
import {
  populateTable,
  setToLink,
  handleAddCustomerDetailsSubmit,
} from '../../../utils/helpers/installerDashboardHelper';
import { getInstallerDashboard } from '../../../utils/helpers/getInstallerDashboard';
import { fetchClaims } from '../../../utils/helpers/fetchClaims';
import { checkSearchTerm } from '../../../regex/regex';

import {
  setIsResidential,
  getClaimsData,
  setClaimsData,
  getIsResidential,
} from '../../../features/customer/customerJourneySlice';
import displayMenu from '../../../hooks/displayMenu';

import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
  setDisplayGoBackLink,
} from '../../../features/app/appSlice';

import useTitle from '../../../hooks/useTitle';

const InstallerDashboard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const INSTALLER_ACCOUNT_TYPE = 'installer';
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;
  useTitle(
    'Installer.Dashboard.InstallerDashboard.s29',
    'Manage your claims - OZEV Electric Vehicle Chargepoint Grant - GOV.UK'
  );
  const claimsDataValue = useSelector(getClaimsData);
  const isResidential = useSelector(getIsResidential);

  const [searchTerm, setSearchTerm] = useState('');
  const [searchHasResultNo, setSearchHasNoResult] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const recordsPerPage = 50;
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const mapDashboardStatuses = {
    'READY TO CLAIM': 'READY TO CLAIM',
    'SUBMITTED FOR REVIEW': 'SUBMITTED FOR REVIEW',
    'WAITING DYNAMICS': 'SUBMITTED FOR REVIEW',
    'APPROVED FOR PAYMENT': 'APPROVED FOR PAYMENT',
    DECLINED: 'DECLINED',
    CANCELLED: 'CANCELLED',
    EXPIRED: 'EXPIRED',
    'READY FOR INSTALLER CLAIM': 'READY TO CLAIM',
    'INSTALLER CLAIM INCOMPLETE': 'INCOMPLETE',
    'INSTALLER CLAIMED': 'SUBMITTED FOR REVIEW',
    PAYMENT: 'APPROVED FOR PAYMENT',
    APPROVED: 'APPROVED FOR PAYMENT',
    'Installer Claimed': 'SUBMITTED FOR REVIEW',
    REJECTED: 'REJECTED',
    RESUBMITTED: 'SUBMITTED FOR REVIEW',
    'SEND TO DYNAMICS': 'SUBMITTED FOR REVIEW',
    'EXPIRING SOON': 'EXPIRING SOON',
  };

  const searchTermErrorMsg = copyProvider.getCopy(
    'Installer.Dashboard.InstallerDashboard.s17',
    'Enter 3 or more characters'
  );

  const tableHead = [
    {
      children: copyProvider.getCopy(
        'Installer.Dashboard.InstallerDashboard.s5',
        'Reference number'
      ),
    },
    {
      children: copyProvider.getCopy(
        'Installer.Dashboard.InstallerDashboard.s6',
        'Grant type'
      ),
    },
    {
      children: copyProvider.getCopy(
        'Installer.Dashboard.InstallerDashboard.s7',
        'Customer'
      ),
    },
    {
      children: copyProvider.getCopy(
        'Installer.Dashboard.InstallerDashboard.s8',
        'Postcode'
      ),
    },
    {
      children: copyProvider.getCopy(
        'Installer.Dashboard.InstallerDashboard.s9',
        'Claim status'
      ),
    },
    {
      children: copyProvider.getCopy(
        'Installer.Dashboard.InstallerDashboard.s10',
        'Action'
      ),
    },
  ];

  const handleLinkClick = (customer) => {
    return customer.claimStatus.toUpperCase() === 'EXPIRING SOON'
      ? window.location.assign(`
                    ${setToLink(
                      customer.claimStatus.toUpperCase(),
                      customer.grant_type
                    )}/${customer.grant_type}/${customer.document_id}`)
      : window.location.assign(`
                      ${setToLink(
                        customer.claimStatus.toUpperCase(),
                        customer.grant_type
                      )}/${customer.document_id}`);
  };

  displayMenu();

  useEffect(() => {
    if (
      isResidential !== undefined ||
      isResidential !== null ||
      isResidential !== ''
    )
      getInstallerDashboard(
        getInstallerDetails,
        getClaimsByInstallerId,
        dispatch,
        setIsResidential,
        INSTALLER_ACCOUNT_TYPE
      );

    dispatch(setDisplayGoBackLink(false));
    fetchClaims(
      getClaimsByInstallerId,
      setTotalRecords,
      currentPage,
      recordsPerPage,
      dispatch,
      setClaimsData,
      history
    );
    return () => dispatch(setDisplayGoBackLink(true));
  }, [currentPage]);

  const onSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <>
      {errorList?.length > 0 && (
        <ErrorSummary
          errorList={errorList}
          titleChildren={copyProvider.getCopy(
            'Common.error-summary',
            'There is a problem'
          )}
        />
      )}
      {isResidential && (
        <>
          <div className="govuk-grid-row">
            <div className="govuk-grid-column-two-third">
              <h1 className="govuk-heading-xl">
                {copyProvider.getCopy(
                  'Installer.Dashboard.InstallerDashboard.s1',
                  'Manage your claims'
                )}
              </h1>
            </div>
          </div>

          <div className="govuk-grid-row">
            <div className="govuk-grid-column-two-third">
              <p className="govuk-body">
                {copyProvider.getCopy(
                  'Installer.Dashboard.InstallerDashboard.s2',
                  'To begin a new claim for the'
                )}{' '}
                <b>
                  {copyProvider.getCopy(
                    'Installer.Dashboard.InstallerDashboard.s3',
                    'renters and flat owners grant'
                  )}
                </b>
                <br />
                <Link to={{}} onClick={() => handleAddCustomerDetailsSubmit()}>
                  {copyProvider.getCopy(
                    'Installer.Dashboard.InstallerDashboard.s4',
                    "enter your customer's details."
                  )}
                </Link>
              </p>
            </div>
            <br />
            <hr className="govuk-section-break govuk-section-break--l govuk-section-break--visible" />
          </div>
        </>
      )}
      <div className="govuk-grid-row govuk-!-margin-top-4">
        <div className="moj-search">
          <form
            action="#"
            method="post"
            onSubmit={(event) => {
              event.preventDefault(); // Prevent page reload
              handleSearch({
                event,
                searchTerm,
                checkSearchTerm,
                searchTermErrorMsg,
                setErrorMsg,
                setErrorList,
                getClaimsByInstallerId,
                dispatch,
                setClaimsData,
                setSearchPerformed,
                setSearchHasNoResult,
              });
            }}
          >
            <Input
              className="govuk-input moj-search__input"
              id="search"
              hint={{
                children: copyProvider.getCopy(
                  'Installer.Dashboard.InstallerDashboard.s11',
                  'You can search using the customer name, postcode and reference number'
                ),
              }}
              label={{
                children: (
                  <h2 className="govuk-heading-m">
                    {copyProvider.getCopy(
                      'Installer.Dashboard.InstallerDashboard.s12',
                      'Find a claim'
                    )}
                  </h2>
                ),
              }}
              value={searchTerm}
              errorMessage={errorMsg}
              name="search"
              type="search"
              onChange={onSearchChange}
            />
            <Button
              className="moj-search__button"
              data-module="govuk-button"
              type="submit"
            >
              {copyProvider.getCopy(
                'Installer.Dashboard.InstallerDashboard.s13',
                'Search'
              )}
            </Button>
          </form>
        </div>
      </div>
      {searchHasResultNo && (
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-one-half">
            <h3 className="govuk-heading-s">
              {copyProvider.getCopy(
                'Installer.Dashboard.InstallerDashboard.s14',
                'There are no matching results'
              )}
            </h3>
            <p className="govuk-body">
              {copyProvider.getCopy(
                'Installer.Dashboard.InstallerDashboard.s18',
                'Improve your search results by:'
              )}
            </p>
            <ul className="govuk-list govuk-list--bullet">
              <li>
                {copyProvider.getCopy(
                  'Installer.Dashboard.InstallerDashboard.s15',
                  'double-checking your spelling'
                )}
              </li>
              <li>
                {copyProvider.getCopy(
                  'Installer.Dashboard.InstallerDashboard.s16',
                  'using few keywords'
                )}
              </li>
            </ul>
            <a
              href="/installer/claim-grant/installer-dashboard"
              className="govuk-link "
            >
              <span role="presentation">
                {copyProvider.getCopy(
                  'Installer.Dashboard.InstallerDashboard.s19',
                  'Clear search'
                )}
              </span>
            </a>
          </div>
        </div>
      )}
      {searchPerformed && !searchHasResultNo && (
        <div className="govuk-grid-row">
          <Table
            data-testid="claims-table"
            head={tableHead}
            rows={populateTable(
              claimsDataValue,
              mapDashboardStatuses,
              handleLinkClick,
              copyProvider
            )}
          />
          <a
            href="/installer/claim-grant/installer-dashboard"
            className="govuk-link "
          >
            <span role="presentation">
              {copyProvider.getCopy(
                '.ClaimGrant.InstallerDashboard.s19',
                'Clear search'
              )}
            </span>
          </a>
        </div>
      )}
      {!searchPerformed && !searchHasResultNo && (
        <div className="govuk-grid-row">
          <Table
            data-testid="claim-tablee"
            head={tableHead}
            rows={populateTable(
              claimsDataValue,
              mapDashboardStatuses,
              handleLinkClick,
              copyProvider
            )}
          />
        </div>
      )}
      {!searchTerm && (
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          searchTerm={searchTerm}
          totalRecords={totalRecords}
          recordsPerPage={recordsPerPage}
        />
      )}
    </>
  );
};

export default InstallerDashboard;
