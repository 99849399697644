/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import {
  Input,
  Button,
  Table,
  Tag,
  ErrorSummary,
  Label,
} from 'govuk-react-jsx';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../../features/app/appSlice';
import {
  getCarParkClientCount,
  setCarParkClientCount,
} from '../../features/dashboard/dashboardSlice';

import {
  getDashboardSmeData,
  updateCarparkClaimsCountersForUser,
} from '../../firebase';
import { handleLandlordSearchSme } from '../../utils/helpers';
import { handleClickSme } from '../../utils/helpers/handleClick';
import useTitle from '../../hooks/useTitle';
import displayMenu from '../../hooks/displayMenu';

const Dashboard = () => {
  const dispatch = useDispatch();
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;
  const carParkClientCount = useSelector(getCarParkClientCount);
  useTitle(
    'dashboardHub.DashboardSME.s29',
    'Manage your applications - OZEV Electric Vehicle Chargepoint Grant - GOV.UK'
  );
  const [isCounterUpdated, setIsCounterUpdated] = useState(false);
  const [tableSMEdRows, setTableSMERows] = useState([]);

  const [searchStatus, setSearchStatus] = useState({
    searched: false,
    searchTerm: '',
    resultCount: 0,
    allData: null,
    errorMessage: '',
  });
  const [errorList, setErrorList] = useState([]);

  const checkTagLabel = (claimStatus) => {
    switch (claimStatus) {
      case 'Sent to Installer':
        return <Tag className="govuk-tag--yellow">{claimStatus}</Tag>;
      case 'Expiring Soon':
        return <Tag className="govuk-tag--orange">{claimStatus}</Tag>;
      case 'Awaiting Customer Review':
        return <Tag className="govuk-tag--red">{claimStatus}</Tag>;
      case 'Submitted by Installer':
        return <Tag className="govuk-tag--blue">{claimStatus}</Tag>;
      case 'Completed':
        return <Tag className="govuk-tag--green">{claimStatus}</Tag>;
      case 'Declined':
        return <Tag className="govuk-tag--grey">{claimStatus}</Tag>;
      case 'Expired':
        return <Tag className="govuk-tag--grey">{claimStatus}</Tag>;
      default:
        return <Tag className="govuk-tag--grey">{claimStatus}</Tag>;
    }
  };

  const handleSMESearchInputChange = (e) => {
    const { value } = e.target;

    setSearchStatus({
      ...searchStatus,
      searchTerm: value,
    });
  };

  const clearSMESearch = (e) => {
    e.preventDefault();
    setTableSMERows(searchStatus.allData);
    setSearchStatus({
      searched: false,
      searchTerm: '',
      resultCount: 0,
      allData: null,
      errorMessage: '',
    });
    setErrorList([]);
  };

  const capitalizeFirstLetter = (word) => {
    return word !== undefined && word
      ? word.charAt(0).toUpperCase() + word.slice(1)
      : '';
  };

  displayMenu();

  useEffect(() => {
    updateCarparkClaimsCountersForUser()
      .then((response) => {
        const { data } = response;
        if (data.success) {
          dispatch(setCarParkClientCount(data.claimsCounter));
          setIsCounterUpdated(true);
        }
      })
      .catch(() => {
        // TODO: Show error message
      });

    getDashboardSmeData()
      .then((response) => {
        const { data } = response;
        const tableRowsLocal = [];
        data.arr.map((eachData) => {
          return tableRowsLocal.push({
            cells: [
              { children: eachData.claim_id },
              { children: capitalizeFirstLetter(eachData.grant_type_display) },
              { children: eachData.installation_address_postcode },
              {
                children: checkTagLabel(eachData.claim_status),
              },
              {
                children: (
                  <Link
                    to={{}}
                    onClick={() => handleClickSme(eachData, dispatch)}
                  >
                    {eachData.action}
                  </Link>
                ),
              },
            ],
          });
        });

        const tableSMEdRowsLocal = [];
        data.arr.map((eachData) => {
          return tableSMEdRowsLocal.push({
            cells: [
              { children: eachData.claim_id },
              { children: capitalizeFirstLetter(eachData.grant_type_display) },
              { children: eachData.installation_address_postcode },
              {
                children: checkTagLabel(eachData.claim_status),
              },
              {
                children: (
                  <Link
                    to={{}}
                    onClick={() => handleClickSme(eachData, dispatch)}
                  >
                    {eachData.action}
                  </Link>
                ),
              },
            ],
          });
        });
        setTableSMERows(tableSMEdRowsLocal);
      })
      .catch(() => {});
  }, []);

  const smeWithoutApplications = (
    <>
      <h1 className="govuk-heading-xl">
        {copyProvider.getCopy(
          'dashboardHub.DashboardSME.s1',
          'Manage your applications'
        )}
      </h1>
      <p className="govuk-body">
        {copyProvider.getCopy(
          'dashboardHub.DashboardSME.s3',
          'You have not started any grant applications yet.'
        )}
      </p>
      <p className="govuk-body">
        {copyProvider.getCopy(
          'dashboardHub.DashboardSME.s4',
          'Once you submit an application, you will be able to see it and track its progress here.'
        )}
      </p>
      <p className="govuk-body">
        {copyProvider.getCopy(
          'dashboardHub.DashboardSME.s5',
          'You can view your account details in ‘Your account’ at the top of this page.'
        )}
      </p>
      <Button
        className="govuk-button govuk-!-margin-top-3"
        onClick={() =>
          window.location.assign('/car-parks/sme-app/staff-and-fleet')
        }
      >
        {copyProvider.getCopy(
          'dashboardHub.DashboardSME.s2',
          'Start a new application'
        )}
      </Button>
    </>
  );

  const smeTable = (
    <>
      {errorList?.length > 0 && (
        <ErrorSummary
          errorList={errorList}
          titleChildren={copyProvider.getCopy(
            'Common.error-summary',
            'There is a problem'
          )}
        />
      )}
      <h1 className="govuk-heading-xl">
        {copyProvider.getCopy(
          'dashboardHub.DashboardSME.s6',
          'Manage your applications'
        )}
      </h1>
      {carParkClientCount < 5 && isCounterUpdated && (
        <Button
          onClick={() =>
            window.location.assign('/car-parks/sme-app/staff-and-fleet')
          }
        >
          {copyProvider.getCopy(
            'dashboardHub.DashboardSME.s9',
            'Start a new application'
          )}
        </Button>
      )}
      {carParkClientCount === 5 && isCounterUpdated && (
        <p className="govuk-body">
          {copyProvider.getCopy(
            'dashboardHub.DashboardSME.s30',
            'You have already made your limit of 5 grant applications'
          )}
        </p>
      )}
      <hr />
      <div className="govuk-grid-row">
        <div className="govuk-grid-column-two-thirds">
          <h2 className="govuk-heading-m">
            <Label htmlFor="input-with-hint-text">
              {copyProvider.getCopy(
                'dashboardHub.DashboardSME.s10',
                'Find an application'
              )}
            </Label>
          </h2>
          <Input
            hint={{
              children: copyProvider.getCopy(
                'dashboardHub.DashboardSME.s19',
                'You can search by reference number or postcode'
              ),
            }}
            id="input-with-hint-text"
            name="test-name-2"
            type="text"
            onChange={handleSMESearchInputChange}
            value={searchStatus.searchTerm}
            errorMessage={
              searchStatus.errorMessage.length && {
                children: searchStatus.errorMessage,
              }
            }
          />
        </div>
        <div className="govuk-grid-column-one-third app-padding-top-dashboard">
          <Button
            onClick={() =>
              handleLandlordSearchSme({
                searchStatus,
                setSearchStatus,
                setErrorList,
                tableSMEdRows,
                copyProvider,
                setTableSMERows,
              })
            }
          >
            {copyProvider.getCopy('dashboardHub.DashboardSME.s12', 'Search')}
          </Button>
        </div>
      </div>
      {searchStatus.searched && searchStatus.resultCount === 0 && (
        <>
          <h3 className="govuk-heading-s">
            {copyProvider.getCopy(
              'dashboardHub.DashboardSME.s21',
              'There are no matching results'
            )}
          </h3>
          <p className="govuk-body">
            {copyProvider.getCopy(
              'dashboardHub.DashboardSME.s22',
              'Improve your search results by:'
            )}
          </p>
          <ul className="govuk-list govuk-list--bullet">
            <li>
              {copyProvider.getCopy(
                'dashboardHub.DashboardSME.s23',
                'double-checking your spelling'
              )}
            </li>
            <li>
              {copyProvider.getCopy(
                'dashboardHub.DashboardSME.s24',
                'using fewer keywords'
              )}
            </li>
          </ul>
          <Link
            className="gov-link"
            style={{ fontWeight: 'normal' }}
            onClick={clearSMESearch}
          >
            {copyProvider.getCopy(
              'dashboardHub.DashboardSME.s20',
              'Clear search'
            )}
          </Link>
        </>
      )}
      {(!searchStatus.searched || searchStatus.resultCount > 0) && (
        <>
          {searchStatus.searched && searchStatus.resultCount > 0 && (
            <>
              <p className="govuk-heading-s" role="status">
                {searchStatus.resultCount}{' '}
                {searchStatus.resultCount > 1
                  ? copyProvider.getCopy(
                      'dashboardHub.DashboardSME.s25',
                      'applications'
                    )
                  : copyProvider.getCopy(
                      'dashboardHub.DashboardSME.s26',
                      'application'
                    )}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Link style={{ fontWeight: 'normal' }} onClick={clearSMESearch}>
                  {copyProvider.getCopy(
                    'dashboardHub.DashboardSME.s20',
                    'Clear search'
                  )}
                </Link>
              </p>
              <p />
              <hr />
            </>
          )}
          <Table
            head={[
              {
                children: copyProvider.getCopy(
                  'dashboardHub.DashboardSME.s13',
                  'Reference number'
                ),
              },
              {
                children: copyProvider.getCopy(
                  'dashboardHub.DashboardSME.s14',
                  'Grant type'
                ),
              },
              {
                children: copyProvider.getCopy(
                  'dashboardHub.DashboardSME.s16',
                  'Postcode'
                ),
              },
              {
                children: copyProvider.getCopy(
                  'dashboardHub.DashboardSME.s17',
                  'Claim status'
                ),
              },
              {
                children: copyProvider.getCopy(
                  'dashboardHub.DashboardSME.s18',
                  'Action'
                ),
              },
            ]}
            rows={tableSMEdRows.map((row) => row)}
          />
        </>
      )}
    </>
  );

  const YourDashboard = (
    <div className="govuk-width-container">
      {tableSMEdRows.length === 0 && smeWithoutApplications}

      {tableSMEdRows.length > 0 && smeTable}
    </div>
  );

  return YourDashboard;
};

export default Dashboard;
