import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { CompanyVatNumber } from '@pepper-ui/common';
import {
  setBusinessVatRegistration,
  setBusinessVatData,
} from '../../features/registration/registrationJourneySlice';
import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../../features/app/appSlice';
import { checkVATNumber } from '../../firebase';
import useTitle from '../../hooks/useTitle';

const BusinessVatRegistration = () => {
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;

  const dispatch = useDispatch();

  const history = useHistory();
  const [serviceErrorNotAPIValid, setServiceErrorNotAPIValid] = useState(false);
  const [serviceErrorAlreadyExists, setServiceErrorAlreadyExists] =
    useState(false);
  const [businessVatRegistrationLocal, setBusinessVatRegistrationLocal] =
    useState('');
  const handleBusinessVatRegistration = (event) => {
    setBusinessVatRegistrationLocal(event.target.value);
  };
  useTitle(
    'SMEReg.CompanyVATNumber.s10',
    'Enter VAT registration number - OZEV Electric Vehicle Chargepoint Grant - GOV.UK'
  );

  const handleLink = () => {
    history.push('/sme-reg/cannot-proceed');
  };

  const handleBtnClick = () => {
    dispatch(setBusinessVatRegistration(businessVatRegistrationLocal));
    checkVATNumber({ vatRegNumber: businessVatRegistrationLocal })
      .then((response) => {
        const { data } = response;
        dispatch(setBusinessVatData(data));
        if (
          data !== null &&
          data.vatRegNumber === businessVatRegistrationLocal
        ) {
          history.push('/sme-Reg/confirm-business-details');
        } else {
          setServiceErrorNotAPIValid(true);
        }
      })
      .catch((error) => {
        if (error.code === 'functions/already-exists') {
          setServiceErrorAlreadyExists(true);
        } else {
          setServiceErrorNotAPIValid(true);
        }
      });
  };
  const pageModel = (
    <CompanyVatNumber
      summary={{
        title: copyProvider.getCopy(
          'Common.error-summary',
          'There is a problem'
        ),
      }}
      body={{
        title: copyProvider.getCopy(
          'SMEReg.CompanyVATNumber.s1',
          'Enter your VAT registration number'
        ),
        paragraph: copyProvider.getCopy(
          'SMEReg.CompanyVATNumber.s2',
          'We will use this to confirm your VAT details.'
        ),
      }}
      inputComp={{
        errorMsg: copyProvider.getCopy(
          'SMEReg.CompanyVATNumber.s7',
          'Enter a valid VAT Registration number that consists of 9 characters and can contain letters and numbers'
        ),

        label: copyProvider.getCopy(
          'SMEReg.CompanyVATNumber.s3',
          'UK VAT registration number'
        ),
        hint: copyProvider.getCopy(
          'SMEReg.CompanyVATNumber.s4',
          'This is the unique number that HMRC provides to UK businesses when they register for tax payment'
        ),
        value: businessVatRegistrationLocal,
        onChange: handleBusinessVatRegistration,
        businessVatRegistrationLocal: '',
      }}
      btn={{
        handleBtnClick,
        text: copyProvider.getCopy('SMEReg.CompanyVATNumber.s6', 'Continue'),
      }}
      link={{
        text: copyProvider.getCopy(
          'SMEReg.CompanyVATNumber.s5',
          'I do not have a VAT registration number'
        ),
        to: '/',
        onClick: handleLink,
      }}
      errorMessages={{
        errorMsgNotValid: copyProvider.getCopy(
          'SMEReg.CompanyVATNumber.s8',
          'There is no company that matches the UK VAT registration number you have provided'
        ),
        errorMsgAlreadyExists: copyProvider.getCopy(
          'SMEReg.CompanyVATNumber.s9',
          'This number is already in use with another account'
        ),

        errorMsgValid: copyProvider.getCopy(
          'SMEReg.CompanyVATNumber.s7',
          'Enter a valid VAT Registration number that consists of 9 characters and can contain letters and numbers'
        ),

        serviceError: false,
      }}
      serviceErrors={{
        errorMsgNotValid: {
          hasError: serviceErrorNotAPIValid,
          message: copyProvider.getCopy(
            'SMEReg.CompanyVATNumber.s8',
            'There is no company that matches the UK VAT registration number you have provided'
          ),
        },
        errorMsgAlreadyExists: {
          hasError: serviceErrorAlreadyExists,
          message: copyProvider.getCopy(
            'SMEReg.CompanyVATNumber.s9',
            'This number is already in use with another account'
          ),
        },
      }}
    />
  );

  return pageModel;
};

export default BusinessVatRegistration;
