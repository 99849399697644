import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AccountType as CommonAccountType } from '@pepper-ui/common';
import { setAccountTypeSelected } from '../features/registration/registrationJourneySlice';
import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../features/app/appSlice';
import { chooseAccountType, signInRegistration } from '../firebase';
import useGaAccountTypeStartEvent from '../hooks/useGaAccountTypeStartEvent';
import useTitle from '../hooks/useTitle';

const AccountType = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [hasError, setHasError] = useState(false);
  const [getAccountTypeSelectedLocal, setAccountTypeSelectedLocal] =
    useState('');
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;

  useTitle(
    'SMEReg.AccountType.s15',
    'What type of account do you need? - OZEV Electric Vehicle Chargepoint Grant - GOV.UK'
  );

  useGaAccountTypeStartEvent(getAccountTypeSelectedLocal);

  const legendTitle = copyProvider.getCopy(
    'SMEReg.AccountType.s1',
    'What type of account do you need?'
  );

  const errorMessage = copyProvider.getCopy(
    'SMEReg.AccountType.s2',
    'Select a User'
  );

  const accountType = () => {
    chooseAccountType({ accountType: getAccountTypeSelectedLocal })
      .then(() => {
        setHasError(false);
        if (getAccountTypeSelectedLocal === 'Property Management Company') {
          window.location.assign('/evcg/pmcReg/create-account');
        } else if (
          getAccountTypeSelectedLocal === 'Small to medium enterprise'
        ) {
          history.push('/sme-reg/create-account');
        } else {
          window.location.assign('/evcg/landlordReg/create-account');
        }
      })
      .catch(() => {
        setHasError(true);
      });
  };

  const newRegistration = () => {
    signInRegistration()
      .then(() => {
        accountType();
      })
      .catch(() => {
        setHasError(true);
      });
  };

  const handleSelection = (event) => {
    setAccountTypeSelectedLocal(event.target.value);
  };

  const handleBtnClick = () => {
    dispatch(setAccountTypeSelected(getAccountTypeSelectedLocal));
    if (getAccountTypeSelectedLocal === 'Installer') {
      window.location.assign('/evcg/installer/installer-account-guidance');
    } else {
      newRegistration();
    }
  };

  let optionalAccountTypes = [];
  if (process.env.REACT_APP_SHOW_OPTIONAL_ACCOUNT_TYPES === 'true') {
    optionalAccountTypes = [
      {
        children: (
          <span key="0" className="govuk-!-margin-bottom-1">
            {copyProvider.getCopy(
              'SMEReg.AccountType.s8',
              'Property management company'
            )}
          </span>
        ),
        hint: {
          children: (
            <span key="0">
              {copyProvider.getCopy(
                'SMEReg.AccountType.s9',
                'You are the representative or managing agent of one or more landlords applying on behalf of your clients.'
              )}
            </span>
          ),
        },
        value: 'Property Management Company',
      },
      {
        children: (
          <span key="0" className="govuk-!-margin-bottom-1">
            {copyProvider.getCopy(
              'SMEReg.AccountType.s10',
              'Small to medium enterprise (SME)'
            )}
          </span>
        ),
        hint: {
          children: (
            <span key="0">
              {copyProvider.getCopy(
                'SMEReg.AccountType.s11',
                'You are the owner of an SME applying to install infrastructure and chargepoints for staff or fleet use.'
              )}
            </span>
          ),
        },
        value: 'Small to medium enterprise',
      },
    ];
  }
  const pageModel = (
    <CommonAccountType
      summary={{
        title: copyProvider.getCopy(
          'Common.error-summary',
          'There is a problem'
        ),
      }}
      legendTitle={legendTitle}
      items={[
        {
          children: (
            <span key="0" className="govuk-!-margin-bottom-1">
              {copyProvider.getCopy('SMEReg.AccountType.s3', 'Landlord')}
            </span>
          ),
          hint: {
            children: (
              <div className="govuk-hint govuk-radios__hint">
                {copyProvider.getCopy('SMEReg.AccountType.s4', 'You are:')}
                <ul className="govuk-list govuk-list--bullet govuk-hint">
                  <li>
                    {copyProvider.getCopy(
                      'SMEReg.AccountType.s5',
                      'The freeholder or leaseholder applying for properties you own or let'
                    )}
                  </li>
                  <li>
                    {copyProvider.getCopy(
                      'SMEReg.AccountType.s6',
                      'leaseholders applying as a Right to Manage company (RTM)'
                    )}
                  </li>
                  <li>
                    {copyProvider.getCopy(
                      'SMEReg.AccountType.s7',
                      'or a property factor in Scotland'
                    )}
                  </li>
                </ul>
              </div>
            ),
          },
          value: 'Landlord',
        },
        ...optionalAccountTypes,
        {
          children: (
            <span key="1" className="govuk-!-margin-bottom-1">
              {copyProvider.getCopy('SMEReg.AccountType.s12', 'Installer')}
            </span>
          ),
          hint: {
            children: (
              <span key="1">
                {copyProvider.getCopy(
                  'SMEReg.AccountType.s13',
                  'You are the installer whose company is responsible for installing the chargepoint.'
                )}
              </span>
            ),
          },
          value: 'Installer',
        },
      ]}
      onChange={handleSelection}
      value={getAccountTypeSelectedLocal}
      btnText={copyProvider.getCopy('SMEReg.AccountType.s14', 'Continue')}
      onClickContinue={handleBtnClick}
      errorMessage={errorMessage}
      serviceError={hasError}
    />
  );

  return pageModel;
};

export default AccountType;
